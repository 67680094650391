import React from "react";
import { Link, graphql } from "gatsby";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SEO from "../components/seo";

import { StickyContainer, Sticky } from "react-sticky";

import { BsArrowRight } from "react-icons/bs";
import { RiArrowRightUpLine } from "react-icons/ri";

import { get } from "lodash-es";

import "@brainhubeu/react-carousel/lib/style.css";

class Brands extends React.Component {
  state = {
    activeCategory: null,
  };

  chooseCategory(category) {
    this.setState({
      ...this.state,
      activeCategory: category,
    });
  }

  render() {
    const { data } = this.props;
    let allBrands = data.allMdx.edges;

    const siteTitle = data.site.siteMetadata.title;

    const { activeCategory } = this.state;

    let allBrandsToShow = allBrands;
    if (activeCategory !== null) {
      allBrandsToShow = allBrandsToShow.filter(
        ({ node }) => node.frontmatter.category === activeCategory
      );
    }

    const allDistributionLines = allBrandsToShow.filter(
      ({ node }) => node.frontmatter.isDistributionLine === true
    );

    const BrandCategories = () => (
      <>
        <p className="cp-title">BROWSE CATEGORIES</p>

        <ul>
          <li>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                this.chooseCategory(null);
              }}
              className={activeCategory === null ? "cp-active" : ""}
            >
              All
            </a>
          </li>

          {[
            "Bath & body",
            "Beauty",
            "Hair",
            "Men's",
            "Premium bath",
            "Sanitizers",
            "Wellness",
          ].map((category) => (
            <li key={category}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.chooseCategory(category);
                }}
                className={activeCategory === category ? "cp-active" : ""}
              >
                {category}
              </a>
            </li>
          ))}
        </ul>
      </>
    );

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        HeaderExtension={
          <div className="header_extension hero_style brands">
            <div className="cp-mobile-bg is-mobile-only"></div>

            <div className="cp-content">
              <div className="container">
                <h1>Our brands</h1>

                <p>Covering all of your self-care needs, from head to toe.</p>
              </div>
            </div>
          </div>
        }
      >
        <SEO
          title="Our brands"
          keywords={[
            "Skincare, Skincare brands, Personal care, Personal care products, Men’s skincare, Men’s skincare products, Skincare products, Danielle Beauty, Danielle Mirrors, Therawell, Wanderflower, Studio Dry",
          ]}
          description="Browse the best skincare brands, personal care products, and men’s skincare products including Danielle Beauty, Danielle Mirrors,Therawell, Wanderflower and Studio Dry."
        />

        <div className="page-content pages-index">
          <div className="section-brands" style={{ paddingBottom: 0 }}>
            <StickyContainer>
              <div className="container">
                <div className="columns">
                  <div className="column is-2 cp-browse">
                    <Sticky topOffset={-112}>
                      {({
                        style,
                        // the following are also available but unused in this example
                        isSticky,
                        wasSticky,
                        distanceFromTop,
                        distanceFromBottom,
                        calculatedHeight,
                      }) => (
                        <div style={style} className="cp-sticky is-wide-only">
                          <BrandCategories />
                        </div>
                      )}
                    </Sticky>

                    <div className="is-mobile-only cp-browse-mobile">
                      <BrandCategories />
                    </div>
                  </div>

                  <div className="column is-1 is-wide-only"></div>

                  <div className="column is-9">
                    <div className="columns is-multiline">
                      {allBrandsToShow
                        .filter(({ node }) => node.frontmatter.categories !== null && node.frontmatter.isDistributionLine !== true)
                        .map(({ node }) => {
                          const image = getImage(
                            node.frontmatter.thumbnail ||
                              node.frontmatter.featuredImage
                          );

                          return (
                            <div
                              className="column is-4 brand-grid"
                              key={node.fields.slug}
                            >
                              <Link to={`/brands${node.fields.slug}`}>
                                <GatsbyImage
                                  image={image}
                                  alt={node.frontmatter.title}
                                />

                                <p className="cp-category">
                                  {node.frontmatter.category}
                                </p>

                                <p className="cp-title">
                                  {node.frontmatter.title} <BsArrowRight />
                                </p>

                                <p>{node.frontmatter.description}</p>
                              </Link>
                            </div>
                          );
                        })}
                    </div>

                    {allBrandsToShow
                      .filter(
                        ({ node }) =>
                          node.frontmatter.categories === null &&
                          node.frontmatter.isDistributionLine !== true
                      )
                      .sort((a, b) => {
                        return a.node.frontmatter.title.localeCompare(
                          b.node.frontmatter.title
                        );
                      })
                      .map(({ node }) => {
                        const image = getImage(
                          node.frontmatter.thumbnail ||
                            node.frontmatter.featuredImage
                        );

                        return (
                          <div
                            className="columns is-vcentered"
                            key={node.fields.slug}
                          >
                            <div className="column cp-image-box is-4">
                              <div className="cp-img-bordered">
                                {image ? (
                                  <GatsbyImage
                                    image={image}
                                    alt={node.frontmatter.title}
                                  />
                                ) : (
                                  <img
                                    src={get(
                                      node.frontmatter,
                                      "featuredImage.publicURL"
                                    )}
                                    alt={node.frontmatter.title}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="column cp-meta-box is-8 brand-list">
                              <p className="cp-category">
                                {node.frontmatter.category}
                              </p>

                              <p className="cp-title">
                                {node.frontmatter.title}
                              </p>

                              <p>{node.frontmatter.description}</p>
                            </div>
                          </div>
                        );
                      })}

                    {allDistributionLines.length > 0 && (
                      <h3>Our distribution lines</h3>
                    )}

                    {allDistributionLines
                      .sort(
                        (a, b) =>
                          b.node.frontmatter.title - a.node.frontmatter.title
                      )
                      .map(({ node }, i) => {
                        const image = getImage(
                          node.frontmatter.thumbnail ||
                            node.frontmatter.featuredImage
                        );

                        return (
                          <div
                            className="columns is-vcentered"
                            style={{
                              marginTop: i === 0 ? "5.5em" : 0,
                            }}
                            key={node.fields.slug}
                          >
                            <div className="column cp-image-box is-4">
                              <div className="cp-img-bordered">
                                {image ? (
                                  <GatsbyImage
                                    image={image}
                                    alt={node.frontmatter.title}
                                  />
                                ) : (
                                  <img
                                    src={get(
                                      node.frontmatter,
                                      "featuredImage.publicURL"
                                    )}
                                    alt={node.frontmatter.title}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="column cp-meta-box is-8 brand-list">
                              <p className="cp-category">
                                {node.frontmatter.category}
                              </p>

                              <p className="cp-title">
                                {node.frontmatter.title}
                              </p>

                              <p>{node.frontmatter.description}</p>

                              <a
                                href={node.frontmatter.catalogueLink}
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                <span>View catalogue</span>
                                <RiArrowRightUpLine />
                              </a>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </StickyContainer>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Brands;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { posttype: { eq: "brands" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            subtitle
            description
            isDistributionLine
            catalogueLink
            category
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
              publicURL
            }
            categories {
              title
              description
              bullets {
                text
              }
              featuredImage {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`;
